import React from "react";
import "./Titip.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Titip = () => {
  const FaradayWa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285283066969&text=Halo%20Rentalroom.id,%20Saya%20lihat%20iklan%20diwebsite,%20bisa%20titip%20kamar%20untuk%20disewakan?%20https://sewa-apartemen-lagoon.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="containertitip">
      <div className="bannertitip">
        <h1>Punya Apartemen Ingin di Sewakan, Kita Bantu Pasarkan</h1>
        <div className="clusterbutton">
          <button onClick={FaradayWa} className="cluster-whatsapp">
            <FontAwesomeIcon size="lg" icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default Titip;
